"use strict";

window.redirectWithMethod = function(url, method, data) {
    var id = 'link_with_method_form';
    var html = '<form style="display:none;" id="'+id+'" action="'+url+'" method="'+(method === 'GET' ? 'GET' : 'POST')+'">';
    if ('GET' !== method) {
        html += '<input type="text" name="_method" value="'+method+'"/>';
    }

    data = data || {};
    for(var i in data) {
        if (data.hasOwnProperty(i)) html += '<input type="text" name="'+i+'" value="'+data[i]+'">';
    }
    html += '</form>';
    document.body.innerHTML+= html;
    document.getElementById(id).submit();
    return false;
}
import jQuery from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/spinner';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';
import './javascripts/jquery.montage.min';
import 'rangeslider.js';
window.$ = window.jquery = window.jQuery = jQuery;

require('slick-carousel');



import './stylesheets/hv.scss';
import './javascripts/helpers';
import './javascripts/main';



require("@fancyapps/fancybox");
require('blueimp-file-upload');

import './javascripts/readmore';
import './javascripts/bootstrap';
import './javascripts/bootstrap-hover-dropdown';
import './javascripts/bootstrap-select.min';
import './javascripts/hinclude';
import './javascripts/site';

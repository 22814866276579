/*** SITENAME - JS code by GBaRT -> http://www.gbart.hu/ - 2014 ***/
'use strict';

// device / os / broswer
function osAndBrowserAndDeviceInit() {

  // get data
  var $ua = window.navigator.userAgent.toLowerCase();
  var $pl = window.navigator.platform.toLowerCase();
  var $ver = window.navigator.appVersion.toLowerCase();
  var $device = 'desktop';
  var $os = '';
  var $browser = 'unknown-browser';

  // devices
  if ($ua.indexOf('iphone') > -1) {
    var $windowHeight = window.screen.height;
    if ( $windowHeight == 480 ) {
      $device = 'idevice iphone iphone4';
    } else if ( $windowHeight == 568 ) {
      $device = 'idevice iphone iphone5';
    } else if ( $windowHeight == 667 ) {
      $device = 'idevice iphone iphone6';
    } else if ( $windowHeight == 736 ) {
      $device = 'idevice iphone iphone6plus';
    } else {
      $device = 'idevice iphone';
    }
  } else if($ua.indexOf('ipad') != -1) {
    $device = 'idevice ipad';
  } else if($ua.indexOf('ipod') != -1) {
    $device = 'idevice ipod';
  } else if($ua.indexOf('android') != -1) {
    $device = 'android';
  } else if($ua.indexOf('playbook') != -1) {
    $device = 'blackberry';
  } else {
    // operating systems
    if($ua.indexOf('mac') != -1) {
      $os = 'mac';
    } else if($ua.indexOf('win') != -1) {
      $os = 'windows';
    } else if($ua.indexOf('x11') != -1) {
      $os = 'unix';
    } else if($ua.indexOf('linux') != -1) {
      $os = 'linux';
    }
  }

  // browsers
  if ($ua.indexOf('msie') != -1){
    /* if ($ver.indexOf('msie 6.') != -1) {
     $browser = 'ie ie6';
     } else if ($ver.indexOf('msie 7.') != -1) {
     $browser = 'ie ie7';
     } else */ if ($ver.indexOf('msie 8.') != -1) {
      $browser = 'ie ie8';
    } else if ($ver.indexOf('msie 9.') != -1) {
      $browser = 'ie ie9';
    } else if ($ver.indexOf('msie 10.') != -1) {
      $browser = 'ie ie10';
    } else {
      $browser = 'ie';
    }
  } else if($ua.indexOf('trident/7') != -1) {
    $browser = 'ie ie11';
  } else if ($ua.match(/opera|opr\//)) {
    $browser = 'opera';
  } else if ($ua.match('crios')) {
    $browser = 'ios-chrome';
  } else if ($ua.indexOf('edge') != -1) {
    $browser = 'edge';
  } else if ($ua.indexOf('chrome') != -1) {
    $browser = 'chrome';
  } else if ($ua.indexOf('safari') != -1) {
    $browser = 'safari';
  } else if ($ua.indexOf('firefox') != -1) {
    $browser = 'firefox';
  }

  // add class
  var $newclasses = $device + ' ' + $os + ' ' + $browser;
  $('html').addClass($newclasses);

};

// IE10 viewport hack for Surface/desktop Windows 8 bug
function ie10ViewportBugWorkaroundInit() {

  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {

    // get data
    var $msViewportStyle = document.createElement('style');

    // create style
    $msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild($msViewportStyle);

  }

};

// fake placeholder
function fakePlaceholderInit() {

  // get data
  var $html = $('html');

  if( /* $html.hasClass('ie6') || $html.hasClass('ie7') || */ $html.hasClass('ie8') || $html.hasClass('ie9') ) {

    $('[placeholder]').each(function() {

      // get data
      var $basicVal = $(this).attr('placeholder');
      var $input = $(this);

      // add basicly
      if( $input.val() == '' ) {
        $input.val($basicVal);
      }

      // focus event
      $input.focus(function() {
        if( $input.val() == $basicVal ) {
          $input.val('');
        }
      });

      // blur event
      $input.blur(function() {
        if( $input.val() == '' ) {
          $input.val($basicVal);
        }
      });

      // submit form
      $input.closest('form').on('submit', function() {
        if($input.val() == $basicVal) {
          $input.val('');
        }
      });

    });

  }

};


// show/hide
function showHideInit() {

  // get data
  var $showHide = $('[data-toggle="show-hide"]');

  if( $showHide.length != 0 ) {

    $showHide.click(function() {

      // get data
      var $target = $(this).attr('data-target');//.event-card

      // add/remove 'open' class
      if( !$(this).hasClass('open') ) {
        $(this).addClass('open');
        //$($target).addClass('open');
        $('.event-card').find().closest('.panel-body-outer').addClass('open');
      } else {
        $(this).removeClass('open');
        //$($target).removeClass('open');
        $('.event-card').find().closest('.panel-body-outer').removeClass('open');
      }

      return false;

    });

  }

  // get data
  var $showHideRemote = $('[data-toggle="show-hide-remote"]');

  if( $showHideRemote.length != 0 ) {

    $showHideRemote.click(function() {

      // get data
      var $target = $(this).attr('data-target');

      // add/remove 'remoted' class
      $('[data-target="'+$target+'"]:first').trigger('click').addClass('remoted');

      return false;

    });

  }

};


// smooth scroll to
function smoothScrollToInit($speed) {

  // get data
  var $smoothScrollTo = $('[data-scroll="smooth-scroll-to"]');

  if( $smoothScrollTo.length != 0 ) {

    $smoothScrollTo.click(function(){

      // get data
      if ( typeof $speed !== 'undefined' ) {
        var $speed = $speed;
      } else {
        var $speed = 500;
      }
      var $targetID = $(this).attr('href');

      if( $($targetID).length != 0 ) {
        var $targetPosition = $($targetID).offset();
        var $targetDistance = 80;//$('header').height();

        // scrolling animation
        $('html, body').animate({
          scrollTop : $targetPosition.top - $targetDistance
        }, $speed, function() {
          if ( !$('html').hasClass('ios-chrome') ){
            location.hash = $targetID;
          }
        });
      }

      return false;

    });

  }

};

// after resize
var afterResizeInit = (function () {

  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = 'No uniqueId!';
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };

})();


// hashtag position
function hashtagPositionsInit($speed, $delay) {

  // get data
  if( typeof $delay !== 'undefined' ) {
    var $delay = $delay;
  } else {
    var $delay = 1000;
  }

  setTimeout(function(){

    // get data
    var $hash = window.location.hash;
    if ( typeof $speed !== 'undefined' ) {
      var $speed = $speed;
    } else {
      var $speed = 500;
    }

    if ( $hash.length != 0 ) {

      var $itemInfo = $($hash).offset();
      $('html, body').animate({
        scrollTop: $itemInfo.top
      }, $speed);

    }

  }, $delay);

};


// header open
function headerOpenInit() {

  // menu open/close
  $('.switch-main-menu').click(function() {
    $('html').toggleClass('open-menu');
  });

  // clicked menu item
  $('.main-menu a').click(function() {
    $('html').removeClass('open-menu');
  });

};

//function sonataMenuPresent() {
//	if( $('body').hasClass('sonata-cms-editor') ) {
//
//		//var editorMenu = $('.sonata-page-top-bar');
//		var sonataMenuHeight = $('.sonata-page-top-bar').height();
//		$('body').css('padding-top', sonataMenuHeight);
//	}
//}






//function panelSHowHide() {
//
//    var clicked=false;
//    $(".pe-ul.pe-panel-footer li.more").on('click', function(event){
//        if(clicked)
//        {
//            clicked=false;
//            $(".panel-body-outer").animate({"bottom": "-62px"});
//
//        }
//        else
//        {
//            clicked=true;
//            $(".panel-body-outer").animate({"bottom": "53px"});
//
//        }
//
//        //event.preventDefault();
//        //event.stopPropagation();
//    });
//};
//
//function panelAnimation(){
//
//    $(".pe-ul.pe-panel-footer li.more").on("click", function(e){
//
//        e.stopPropagation(); e.preventDefault();
//
//		//$(".panel").find(".panel-body-outer").toggleClass('animated');
//		//var parent = $(this).closest('div.panel'); // find closest
//
//		$('.event-card').closest('.panel-body-outer').toggleClass('open');
//
//    });
//}

function clickMoreHover(){

  /*
   $('#buttonId').on('touchstart click', function(e){
   e.stopPropagation(); e.preventDefault();
   //your code here

   });
   */
  $(".pe-ul.pe-panel-footer li.more").on('touchstart click', function(e){

    var parent = $(this).closest('div.panel'); // find closest panel to the more button

    $(parent).toggleClass('open');
    $(".panel-body-outer", parent).toggleClass('open');

//		if( !$(parent).hasClass('open') ) {
//			//add open for selected
//			$(parent).addClass('open');
//			$(".panel-body-outer", parent).addClass('open');
//			//remove open from all others
//
//		}
//        else {
//			//remove all opened
//			$('.panel').removeClass('open');
//			$('.panel-body-outer').removeClass('open');
//        }

    e.stopPropagation();
    e.preventDefault();

  });
}

//LANDING

function showfullpage(url, id)
{
  $("#full-page").removeClass();
  showloader();

  $.get(url, function(data) {
    if(id!=0) {
      $("#full-page").addClass('fullback'+id);
    }
    $("#full-page .full-page-content").html(data);
    fadeoutloader();
  });
}
function showloader()
{
  $("#full-page").show('fast');
  $("#full-page .full-page-load").css('display', 'block');
}
function fadeoutloader()
{
  $("#full-page .full-page-load").fadeOut("fast", function() {    });
}
function hidefullpage()
{
  $("#full-page").hide('fast');
}
function landingBoxHover(){
  $('body').off('hover.boxflip').off('mouseover.boxflip').on('hover.blockflip mouseover.boxflip', '.box-wrapper', function(e){
    $(this).find('.card').addClass('flipped');
    if (this.blurTimer) {
      clearTimeout(this.blurTimer);
    }
  });
  $('body').off('mouseout.boxflip').on('mouseout.boxflip', '.box-wrapper', function(e){
    var self = this;
    this.blurTimer = setTimeout(function() {
      $(self).find('.card').removeClass('flipped');
    }, 300);
  });
}

function showrandom(prev)
{
  if (0 == $(".box-wrapper").length) return;
  var att = null;
  while(!att || att == prev || $(".box-wrapper:eq("+att+")").attr("id") != undefined) {
    att = Math.floor((Math.random()* $('.box-wrapper').length));
  }
  $(".box-wrapper:eq("+att+")").trigger("mouseover");
  setTimeout(function() {
    $(".box-wrapper:eq("+att+")").trigger("mouseout");
    setTimeout(function() { showrandom(att) }, 900);
  }, 2500);
}

var loginPageInitCounter = 0;
function loginPage(){
  if (loginPageInitCounter > 0 && $('.login-content :input:focus').length > 0) {
    return;
  }

  loginPageInitCounter++;
  var windowHeight = $(window).height();
  var docHeight = $(document).height();
  var screenwidth = $(window).width();

  var loginBox = $('.login-box');

  var leftSide = $('.left-side');
  var rightSide = $('.right-side');

  var leftSideW = leftSide.width();
  var leftSideH = leftSide.height();

  var rightSideW = rightSide.width();

  var sideBoxes = leftSideW + rightSideW;
  var loginFooter = $('.login-footer');


  loginBox.width = screenwidth - sideBoxes;
  var loginBoxWidth = screenwidth - sideBoxes;

  loginBox.height = Math.round(leftSideH);
  loginFooter.height = Math.round(windowHeight - loginBox.height);


  loginBox.css('width', loginBoxWidth);
  loginBox.css('height', loginBox.height);
  loginFooter.css('height', (windowHeight - ( loginBox.height)));



  if( $('.fos_user_registration_register').length != 0 ) {

    loginFooter.css('height', '80px');
    loginBox.css('height', 'auto');

    if(screenwidth <= 767) {
        loginBox.insertBefore('.left-side');
    }
    else {
        loginBox.insertAfter('.left-side');
    }

  }
  else {

    if((windowHeight > 1020) && (screenwidth >= 1920)) {//fullHD

      loginFooter.css('height', (windowHeight - ( loginBox.height)));


    }

//			if(screenwidth > 1380) {//laptop
//
//			}
//			else if(screenwidth > 1190) {
//
//
//			}
    else if(screenwidth >= 768) {

      //loginBox.css('height', 720);
      loginBox.insertAfter('.left-side');

        //draggable start
//        $( ".login-box" ).draggable({
//            containment: "body",
//            disabled: false
//        });
        //draggable end


    }
    else if(screenwidth <= 767) {

      loginBox.css('height', 720);
      loginBox.css('width', '100%');
      loginBox.insertBefore('.left-side');
      loginFooter.css('height', '80px');


        //draggable start
//        $( ".login-box" ).draggable({
//            containment: "body",
//            disabled: true
//        });
//
//        loginBox.css('left', 0);
//        loginBox.css('top', 0);

        //draggable end
    }
    else {

      loginBox.insertAfter('.left-side');

    }
  }
}


// bg image
function bgImageInit() {
  var initImageContainer;

  initImageContainer = function() {
    var styleData = '';
    if( $(this).attr('style') != undefined ) {
      styleData = $(this).attr('style');
      if (styleData.match(/background\-image/)) {
        styleData = '';
      }
    }
    var $imageObject = $(this).find('img');
    var imageLink = $imageObject.attr('src');
    var imageAlt = $imageObject.attr('alt');
    $imageObject.css('display', 'none');

    // setup bg image
//        $(this).attr('style', 'background-image: url("'+imageLink+'"); filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+imageLink+'", sizingMethod="scale"); ' + styleData);

    if ( $('html').hasClass('ie8') || $('html').hasClass('ie7')){
      $(this).attr('style', 'background-image: url("'+imageLink+'"); filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+imageLink+'", sizingMethod="scale"); ' + styleData);
    }
    else {
      $(this).attr('style', 'background-image: url("'+imageLink+'");' + styleData);
    }

    // add fake text inside, because some browsers doesn't rendering well the empty elements
    $imageObject.after('<span class="bg-image-text">'+imageAlt+'</span>');

    $($imageObject).on('load', function(){
      var container = $(this).parent()[0];
      initImageContainer.apply(container);
    });

  }

  if( $('.bg-image').length != 0 ) {
    $('.bg-image').each(function() {
      // get data
      initImageContainer.apply(this);

    });
  }

    safariMemoryTable();

};

// Check empty nav tabs
function checkEmptyNavTabs() {
  if ($('.panel-body .nav.nav-tabs li').length == 0) {
    //$('.nav.nav-tabs').css('display','none');
    $('.nav.nav-tabs').remove();
  }
};

function safariMemoryTable() {

    if ( $('.memories-accordion').length != 0 && $('.mac').length != 0 ) {

        var memoryCard = $('.memory-card');
        var memoryImgHeight = $('.memory-img').height();
        var memoryCardHeight = memoryImgHeight / 2;

        $(memoryCard).css('height', memoryCardHeight);

    }
}

function commentSliderInit() {

	var commentSlider = $('.comment-slider');

	var status = $('.comment-slider-counter');
	var statusCurrent = $('.comment-slider-counter .current');
	var statusAll = $('.comment-slider-counter .all');

	commentSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
	  // no dots -> no slides
	  if(!slick.$dots){
		return;
	  }

	  //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
	  var i = (currentSlide ? currentSlide : 0) + 1;
	  // use dots to get some count information
	  statusCurrent.text(i);
	  statusAll.text(slick.$dots[0].children.length);

	});


	if (commentSlider.length != 0) {
	  	commentSlider.each(function () {
		$(this).slick({
				accessibility: false,
				nextArrow: '<button type="button" class="slick-button slider-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
				prevArrow: '<button type="button" class="slick-button slider-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
				appendDots: $(this).parent(),
				buttons: true,
				autoplay: true,
				autoplaySpeed: 5000,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: true,
			});
	  	});
	}
};

function randomPosition(){



	if ($(window).width() >= 1024) {

		if ($(window).width() < 1500) {
			var x = Math.max(0, Math.min(60, Math.ceil(Math.random() * 40)));
			var y = Math.max(0, Math.min(60, Math.ceil(Math.random() * 70)));
		}else{
			var x = Math.max(0, Math.min(60, Math.ceil(Math.random() * 30)));
			var y = Math.max(0, Math.min(60, Math.ceil(Math.random() * 50)));
		}

		$('.comment-bubble').css({
			position: 'absolute',
			top: y + '%',
			left: x + '%',
		});
	}
};


$(document).ready(function() {

  $('.comment-bubble').addClass('loaded');

	commentSliderInit();

	// randomPosition();

  if ($(window).width() < 768) {
    var openLogin = $('.open-login');
    var openRegistrer = $('.open-register');

    // login page
    if ( window.location.pathname == '/login' ) {

      // login
      openLogin.click(function(){
        $(".login-form").removeClass("d-none");
        $(".login-form").addClass("d-block");
        $(".mobile-login").addClass("d-none");
        $(".footer").addClass("d-none");
      });

      // register
      openRegistrer.replaceWith('<a href="/register/" class="btn btn-white open-register">Regisztráció</a>');

    }

    // register page
    if ( window.location.pathname == '/register/' ) {

      // login
      openLogin.replaceWith('<a href="/login" class="btn btn-primary open-login">Belépés</a>');

      // register
      openRegistrer.click(function(){
        $(".login-form").removeClass("d-none");
        $(".login-form").addClass("d-block");
        $(".mobile-login").addClass("d-none");
        $(".footer").addClass("d-none");
      }).trigger('click');
    }

    // resettng page
    if ( window.location.pathname == '/resetting' || window.location.pathname == '/register/check-email'  || window.location.pathname == '/resetting/send-email' || window.location.pathname == '/resetting/check-email' || window.location.pathname.includes('/resetting/reset')) {

      // resetting
      openRegistrer.click(function(){
        $(".login-form").removeClass("d-none");
        $(".login-form").addClass("d-block");
        $(".mobile-login").addClass("d-none");
        $(".footer").addClass("d-none");
      }).trigger('click');
    }
  }

  if ( $("body").hasClass("login-page") ) {
    try {
      loginPage();
      setTimeout(function(){
        $(window).resize();
      }, 500);
    } catch(e){
      console.error(e);
    }
    showrandom(0);
  }


  landingBoxHover();

  //clickMoreHover(status);

  //showHideInit();

  //check sonata menu
  //sonataMenuPresent();

  //panelAnimation();

  clickMoreHover();

  // device / os / broswer
  osAndBrowserAndDeviceInit();

  // fake placeholder
  fakePlaceholderInit();

  // smooth scroll to
  //smoothScrollToInit(500); // setup speed (default: 500)

  bgImageInit();

  $('.main-nav-collapsible > .main-nav-link').click(function(){
    $(this).parent().toggleClass('main-nav-open');
    if( $(this).parent().siblings().hasClass('main-nav-open') ){
      $(this).parent().siblings().removeClass('main-nav-open');
    }
    return false;
  });


  $('.mobile-nav').click(function(){
    $('.main-nav').toggleClass('open');
  });

  $('.back-top').click(function(){
    $('html, body').animate({scrollTop:0}, 500);
    return false;
  });


  $('.dropdown-toggle').click(function() {

    $('.user-menu').addClass('active');

    $(this).next('.dropdown-menu').slideToggle(function() {

      if($(this).is(":hidden")) {
        //alert("this was a slide up");
        $('.user-menu').removeClass('active');
      }
    });
  });

  //$('.selectpicker').selectpicker();


  $(".fancybox").fancybox({
    helpers: {
      overlay: {
        locked: false
      },
      media: {}
    }
  });

  // Check empty nav tabs
  checkEmptyNavTabs();


  //reinit on ajax reload
  $('body').delegate('*', 'reinit', function(e){
    e.stopPropagation();
    bgImageInit();
    if ('undefined' !== typeof window.hinclude) window.hinclude.run();
  })

//    $( function() {
//    $( ".login-box" ).draggable({ containment: "body" });
//  } );

});

// --- Load
$(window).load(function() {

  // hashtag position
  //hashtagPositionsInit(500, 1000); // setup speed and delay (default: 500, 1000)

});



$(window).resize(function() {



  // after resize
  afterResizeInit(function() {

    // smooth wheel
    //smoothWheelInit(20); // setup speed (default: 20)
    loginPage();

    bgImageInit();
    //sonataMenuPresent();
    // device height - use it if needed
    // deviceHeightInit('.main', 100); // setup target element's selector and substract number

  }, 100);


});

$(window).on('resize', function () {
  if (window.innerWidth > 768) $('.navbar-collapse').removeClass('in')
})


window.openShareLinkInNewWindow = function(element) {
  var url = $(element).attr('href');
  window.open(url, 'jobportal_share', 'width=500, height=500');
  return false;
}


function setCookie(cname, cvalue, exdays) {
  var expires;
  if (exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    expires = ";expires="+ d.toUTCString();
  } else {
    expires = '';
  }

  document.cookie = cname + "=" + cvalue + expires + ";path=/";
}
